// main.js
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import TC_Wrapper from "vue-tag-commander";

// Initialize your datalayer
window.tc_vars = [];

const wrapper = TC_Wrapper.getInstance();
// Configure TC_Wrapper here
wrapper.setTcVars({
  environnement_technique_du_site: window.location.href.includes("www.parlons-fourrage.credit-agricole.fr") ? "prod" : "preprod",
});
Promise.all([
  wrapper.addContainer(
    "container_head",
    "https://cdn.tagcommander.com/6609/tc_CAASitesdecomm_20.js",
    "head"
  ),
]).then(() => {
  var options = {
    exclusions: ["datastorage", "deduplication", "internalvars", "privacy"],
  };
  wrapper.reloadAllContainers(options);

  createApp(App)
    .use(store)
    .use(router)
    .mount("#app");
});
